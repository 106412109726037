<template>
  <b-sidebar
    :id="uuid"
    right
    backdrop
    shadow
    bg-variant="white"
    body-class="position-relative"
    class="agent-sidebar"
    footer-class="mb-50"
    :width="customWidth || calcWidth"
    @shown="sidebarShown"  
    @change="sidebarStateChange"  
    :lazy="lazy"
  >
    <!-- @hidden="sidebarHidden"  -->
    <template #header class="bg-default">
      <div class="d-flex justify-content-between align-items-center w-100">
        <h3 class="mb-0">{{ $t(`middleware.list.${middlewareType.i18key}.name`) }}</h3>
        
        <b-button class="p-25" variant="none">
          <b-icon
            icon="x"
            scale="1.5"
            variant="secondary"
            class="cursor-pointer"
            @click="toggleSidebar"
          />
        </b-button>
      </div>
    </template>
    <template #default>
      <div class="sidebar-container" >
        <b-container fluid class="sidebar-fixed-header"> 
          <variables-panel
          :ref="getID('variables-panel')"
          :middleware_id="middlewareID"
          @loaded="configureVars"
          :headerExpandedAtInit="headerExpandedAtInit"
          >
          <template #header>
            <div class="text-muted">
              {{ $t(`middleware.list.${middlewareType.i18key}.description`) }}
            </div>
            <div v-if="middlewareType.docsLink">
              <b-link class="wiki-link" target="_blank" :href="middlewareType.docsLink">
                <b-icon icon="info-circle" class="mr-25"/> {{ $t(`middleware.list.${middlewareType.i18key}.name`)}} Docs
              </b-link>
            </div>
            <div v-else class="py-25"/>
          </template>
        </variables-panel>
        </b-container>

        <vue-perfect-scrollbar class="sidebar-content" >

          <b-overlay :show="isSaving" variant="none" blur="1px">
            <div class="w-100 px-1 control-height">
              
              <slot name="default"></slot>
              
            </div>
          </b-overlay>

        </vue-perfect-scrollbar>

        <b-modal
          ref="confirm-close-modal"
          centered
          ok-variant="favorite"
          title="You have unsaved changes to this Middleware"
          cancel-variant="outline-secondary"
          auto-focus-button="ok"
          @ok="toggleSidebar(true)"
        >
          <div>
            Discard changes and close anyways?
          </div>

          <template #modal-ok>
            Close
          </template>
        </b-modal>

      </div>
    </template>
    <template #footer>
      <slot name="middleware-save-btn">
        <b-row class="mt-1">
          <b-col md="12" class="d-flex justify-content-end">
            <b-button block variant="primary" class="mx-2" @click="save()" :disabled="isSaving">
              <span v-if="isSaving"><b-spinner small></b-spinner> <span class="d-inline-block saving-adjust-text-position">{{ $t("common.terms.saving") }}</span></span>
              <span v-else>{{ $t("common.terms.save") }}</span>
            </b-button>
          </b-col>
        </b-row>
      </slot>
    </template>
  </b-sidebar>
</template>

<script>
import { 
  BPopover,
  BIcon,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BContainer,
  BSidebar,
  BSpinner,
  BTabs,
  BTab,
  BCardText,
  BCardBody,
  BCard,
  BAvatar,
  BLink,
  BFormSelect,
  BFormSelectOption,
  BFormGroup,
  BSkeleton,
  BCollapse,
  BOverlay,
  BModal,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VariablesPanel from "../VariablesPanel.vue";

import CustomInput from "@/views/pages/middleware/MiddlewareManagement/Components/CustomInput.vue";
import HelperTooltip from '@/layouts/components/HelperTooltip'
import MiddlewareType from '@/custom/class/Middleware/Type.js';


import MiddlewaresMixin from '@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewaresMixin.js'

  export default {
    mixins: [MiddlewaresMixin],
    components: {
      BPopover,
      BSidebar,
      BIcon,
      BButton,
      BRow,
      BCol,
      BInputGroup,
      BInputGroupAppend,
      BFormInput,
      BContainer,
      VuePerfectScrollbar,
      VariablesPanel,
      BSpinner,
      BTabs,
      BTab,
      BCardText,
      BCard,
      BAvatar,
      BCardBody,
      BLink,
      CustomInput,
      HelperTooltip,
      BFormSelect,
      BFormGroup,
      BFormSelectOption,
      BSkeleton,
      BCollapse,
      BOverlay,
      BModal,
    },
    props: {
      middlewareID: {
        type: Number,
        default: undefined,
      },
      uuid: {
        type: String,
        required: true,
      },
      component:{
        type: String,
        default: '',
      },
      middlewareType:{
        type: Object,
        default: undefined
      },
      value: {
        type: Object,
        default: undefined,
      },
      customWidth:{
        type: String,
        default: undefined,
      },
      headerExpandedAtInit:{
        type: Boolean,
        default: true,
      },
      lazy:{
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        uuidMap: {},
        isSaving: false,
        isSaving: false,
        middlewareVersionKey: 0,
      }
    },
    mounted() {
      
    },
    computed: {
      variables: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
		  },
    },
    methods: {
      save(){
        this.isSaving = true
        this.$emit('saveMiddleware')
      },
      configureVars() {
        this.variables = this.$refs[this.getID("variables-panel")].getAllVars();
      },
      getID(key) {
        if (this.uuidMap[ key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
      sidebarShown() {
        this.$refs[this.getID('variables-panel')].getVariables()
        this.$emit("shown")
        this.isSaving = false;

        let el = document.getElementsByTagName("html");
        el[0].classList.add("hide-scrollbar");  

      },
      sidebarHidden() {
        this.$emit("close");

        let el = document.getElementsByTagName("html");
        el[0].classList.remove("hide-scrollbar");

        this.middlewareVersionKey = this.middlewareVersionKey + 1 
      },
      toggleSidebar(forceClosed = false) {
        if (forceClosed == true){
          this.$emit('allowClosing')
        }
        this.$root.$emit("bv::toggle::collapse", this.uuid);
      },
      sidebarStateChange(state){
        if (state == false){
          this.$emit('close')
        }
      },
      showConfirmClose(){
        this.$refs['confirm-close-modal'].show()
      }
    }
  }
</script>

<style lang="scss">

header.bg-default,
.b-sidebar-header {
  background-color: #151925 !important;
}
</style>

<style lang="scss" scoped>

  .sidebar-container{
    min-height: 97vh !important;
    height: 97vh !important;
    max-height: 97vh !important;
    height: fit-content !important;
    overflow-y: auto !important;
  }
  
  .sidebar-fixed-header{
    position: sticky !important;
    top: 0 !important;
    z-index: 2;
  }
  
  .sidebar-content{
    min-height: 100%;
    width: 100%;
    padding: 0;
    position: relative !important;
    padding-bottom: 20px;
    margin-bottom: 70px !important;
  }
  

</style>

<style lang="scss"scoped>
.agent-sidebar {

  .b-sidebar-body {
    overflow: hidden !important;
  }

  .control-height {
    position: relative;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    min-height: 45vh !important;
    padding-bottom: 50px;
  }

  .limit-height {
    height: calc(100% - 215px) !important;
  }

  .saving-adjust-text-position {
    position: relative;
    top: 2px;
  }
}

</style>
